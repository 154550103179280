<script lang="ts">
	import { browser } from '$app/environment';
	import { page } from '$app/stores';
	import { createTreeView } from '@melt-ui/svelte';
	import { setContext, onMount } from 'svelte';
	import { writable } from 'svelte/store';
	import { crossfade } from 'svelte/transition';
	import { ID2Page, rootPageID } from '../../content/configuration.gen';
	import type { Crossfade } from '../../types';
	import { cn } from '../../utils/style';
	import MenuRecursive from './menuRecursive.svelte';

	let className = '';
	export { className as class };

	const allIDs = Object.keys(ID2Page);
	const expanded = writable(allIDs);
	const rootPage = ID2Page[rootPageID];

	const ctx = createTreeView({
		expanded,
		onExpandedChange() {
			return allIDs;
		}
	});
	setContext('tree', ctx);
	let ref: HTMLElement;
	const {
		elements: { tree },
		states: { selectedItem }
	} = ctx;

	if (browser)
		onMount(() => {
			return page.subscribe(($page) => {
				if (!ref) return;
				selectedItem.set(ref.querySelector(`[href="/${$page.params['any']}"]`)!);
			});
		});

	const _crossfade = crossfade({
		duration: 300,
		delay: 50
	});
	setContext<Crossfade>('crossfade', _crossfade);
</script>

<ul bind:this={ref} {...$tree} class={cn('menu', className)}>
	<MenuRecursive pagesIDs={rootPage.childrenIDs} on:click />
</ul>
