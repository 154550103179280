<script lang="ts">
	import { createDialog } from '@melt-ui/svelte';
	import Icon from '@iconify/svelte';
	import { fade, fly } from 'svelte/transition';
	import { searchByQuery, type SearchResult } from './search';
	import SearchBarResult from './searchBarResult.svelte';
	import { debounce } from '../../utils/promise';
	import { cn } from '../../utils/style';
	import { browser } from '$app/environment';
	import { flip } from 'svelte/animate';

	let query = '';
	let results: SearchResult[] = [];

	let className = '';
	export { className as class };

	const {
		elements: { trigger, overlay, content, close, portalled },
		states: { open }
	} = createDialog({
		forceVisible: true,
		closeOnEscape: true,
		closeOnOutsideClick: true,
		preventScroll: true,
		role: 'dialog',
		onOpenChange({ next }) {
			query = '';
			results = [];
			return next;
		}
	});

	const input = debounce(350, async () => {
		if (query.length == 0) {
			results = [];
			return;
		}
		results = await searchByQuery(query);
	});

	let height = browser ? window.innerHeight : 0;
</script>

<svelte:window
	on:keydown={(event) => {
		if (event.ctrlKey || event.metaKey) {
			if (event.key === 'k') {
				event.preventDefault();
				open.set(true);
			}
		}
	}}
	on:resize|passive={() => (height = window.visualViewport?.height || window.innerHeight)}
/>

<button
	{...$trigger}
	use:trigger
	class={cn('inline-flex lg:hidden btn btn-circle btn-ghost btn-sm', className)}
	aria-label="Show Searchbar"
>
	<Icon icon="ic:twotone-search" width="20" height="20" />
</button>
<button
	{...$trigger}
	use:trigger
	class={cn(
		'hidden lg:flex w-60 justify-between input input-bordered input-sm items-center gap-2',
		className
	)}
	aria-label="Show Searchbar"
>
	<Icon icon="ic:twotone-search" width="20" height="20" />
	<span class="font-medium opacity-80 mr-auto ml-1">Search...</span>
	<kbd class="kbd kbd-sm">⌘K</kbd>
</button>

{#if $open}
	<div {...$portalled} use:portalled>
		<div
			{...$overlay}
			use:overlay
			class="fixed inset-0 bottom-20 z-50 bg-gradient-to-b from-base-300/50 to-base-300 backdrop-blur-[1px]"
			transition:fade={{ duration: 150 }}
		/>
		<div
			{...$content}
			use:content
			class="fixed inset-0 z-50 grid grid-cols-1 grid-rows-[calc(100vh-5rem)_5rem]"
			style="grid-template-rows: calc(100dvh - 5rem /* 80px */) 5rem /* 80px */;"
		>
			<ul class="overflow-y-scroll p-3 pb-0 md:container md:mx-auto flex flex-col">
				{#each results as result (result.slug)}
					<li animate:flip={{ duration: 300 }} class="w-full mt-auto mb-3 self-end">
						<SearchBarResult {result} on:click={() => open.set(false)} />
					</li>
				{/each}
			</ul>
			<div
				class="card card-compact card-bordered rounded-none bg-base-100/80 backdrop-blur-lg"
				transition:fly={{
					duration: 300,
					y: 50
				}}
			>
				<div class="card-body">
					<div class="join join-horizontal">
						<label class="join-item w-full input input-bordered flex items-center gap-2">
							<Icon icon="ic:twotone-search" width="24" height="24" />
							<input
								type="text"
								placeholder="What are you searching for?"
								bind:value={query}
								on:input={input}
								class="grow border-0 focus:outline-none focus:ring-0"
							/>
						</label>
						<button {...$close} use:close class="join-item btn btn-circle btn-neutral">
							<span class="sr-only">Close search</span>
							<Icon icon="ci:close-md" width="20" height="20" />
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
{/if}
