<script lang="ts">
	import type { TreeView } from '@melt-ui/svelte';
	import { getContext } from 'svelte';
	import { ID2Page, ID2Slug } from '../../content/configuration.gen';
	import { cn } from '../../utils/style';

	const {
		elements: { item, group },
		helpers: { isSelected }
	} = getContext<TreeView>('tree');

	export let pagesIDs: string[];
	export let level = 1;
</script>

{#each pagesIDs as pageID}
	{@const page = ID2Page[pageID]}
	{#if page}
		{@const hasChildren = Boolean(page.childrenIDs)}
		{@const active = $isSelected(page.ID)}
		<li role="none">
			<a
				href={ID2Slug[pageID]}
				{...$item({
					id: page.ID,
					hasChildren
				})}
				use:item
				data-level={level}
				on:click
				class={cn(active && 'text-primary font-semibold')}
			>
				<span class:active>{page.name}</span>
			</a>

			{#if hasChildren}
				<ul {...$group({ id: page.ID })} use:group aria-label="{page.name} Submenu">
					<svelte:self pagesIDs={page.childrenIDs} level={level + 1} on:click />
				</ul>
			{/if}
		</li>
	{/if}
{/each}