<svelte:options immutable />

<script lang="ts">
	import type { z } from 'zod';
	import type { CardSchema } from '../../../gen/validation/card';

	export let block: z.infer<typeof CardSchema>;
</script>

<div
	class="my-10 grid gap-4 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2"
>
	{#each block.items as { title, html }}
		<div class="card card-bordered card-compact bg-base-200 group/card fit">
			<div class="card-body">
				<span class="card-title">{title}</span>
				{@html html}
			</div>
		</div>
	{/each}
</div>

<style lang="postcss">
	@media (min-width: 1024px) {
		.fit {
			&:last-child:nth-child(odd) {
				@apply col-span-2;
			}
		}
	}
</style>
