<script lang="ts">
	import Icon from '@iconify/svelte';
	import { isDark } from '../stores/theme';
	import { cn } from '../utils/style';

	// NEXT Server side render icons <https://iconify.design/docs/libraries/utils/get-icon-data>

	let className = '';
	export { className as class };
</script>

<div class={cn('swap swap-rotate', $isDark && 'swap-active', className)}>
	<div class="swap-off fill-current">
		<Icon icon="solar:sun-fog-bold" width="20" height="20" />
	</div>

	<div class="swap-on fill-current">
		<Icon icon="solar:moon-fog-bold" width="20" height="20" />
	</div>
</div>
