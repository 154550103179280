<script lang="ts">
	import { createDialog } from '@melt-ui/svelte';
	import { fade } from 'svelte/transition';
	import { flyAndScale } from '../../utils/transitionts';
	import { cn } from '../../utils/style';

	let className = '';
	export { className as class };
	export let classButton = '';

	const {
		elements: { trigger, overlay, content, portalled },
		states: { open: _open }
	} = createDialog({
		closeOnOutsideClick: true,
		closeOnEscape: true
	});
	export function close() {
		_open.set(false);
	}
	export function open() {
		_open.set(true);
	}
</script>

<button {...$trigger} use:trigger class={cn(classButton)}>
	<slot name="button">Open</slot>
</button>

{#if $_open}
	<div {...$portalled} use:portalled>
		<div
			{...$overlay}
			use:overlay
			class="fixed inset-0 z-50 bg-gradient-to-b from-base-300/50 to-base-300 backdrop-blur-[1px]"
			transition:fade={{ duration: 150 }}
		/>
		<div class={cn(className)} transition:flyAndScale {...$content} use:content>
			<slot {close} />
		</div>
	</div>
{/if}
