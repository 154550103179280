<svelte:options immutable />

<script lang="ts">
	import type { z } from 'zod';
	import { createAccordion } from '@melt-ui/svelte';
	import { slide } from 'svelte/transition';
	import Icon from '@iconify/svelte';
	import type { AccordionSchema } from '../../../gen/validation/accordion';
	import { cn } from '../../utils/style';

	export let block: z.infer<typeof AccordionSchema>;

	const {
		elements: { content, item, root, trigger },
		helpers: { isSelected }
	} = createAccordion({
		forceVisible: true,
		multiple: block.multiple
	});
</script>

<ul {...$root} class="not-prose join join-vertical w-full">
	{#each block.items as { id, title, html } (id)}
		{@const selected = $isSelected(id || '')}
		<li
			{...$item(id || '')}
			use:item
			class="group/accordion bg-base-200 card card-bordered join-item"
		>
			<header class="flex items-center gap-3 px-5 py-4">
				<button
					type="button"
					{...$trigger(id || '')}
					use:trigger
					class="btn btn-circle btn-sm btn-ghost"
					aria-label={selected ? `Collapse ${title}` : `Expand ${title}`}
				>
					<Icon
						icon="majesticons:chevron-down"
						class={cn('transition-transform', selected ? '-rotate-90' : 'rotate-0')}
						width="20"
						height="20"
					/>
				</button>

				<span>{title}</span>
			</header>
			{#if selected}
				<div use:content transition:slide class="p-6">
					{@html html}
				</div>
			{/if}
		</li>
	{/each}
</ul>
