<svelte:options immutable />

<script lang="ts" context="module">
	const variantsClasses = {
		note: {
			icon: 'fluent:note-28-regular',
			alert: ''
		},
		info: {
			icon: 'quill:info',
			alert: 'alert-info'
		},
		success: {
			icon: 'gg:check-o',
			alert: 'alert-success'
		},
		warning: {
			icon: 'ph:warning',
			alert: 'alert-warning'
		},
		error: {
			icon: 'carbon:error-outline',
			alert: 'alert-error'
		}
	} as const;
</script>

<script lang="ts">
	import type { z } from 'zod';
	import Icon from '@iconify/svelte';
	import type { CalloutSchema } from '../../../gen/validation/callout';
	import { cn } from '../../utils/style';

	export let block: z.infer<typeof CalloutSchema>;

	const classes = variantsClasses[block.variant];
</script>

<div class={cn('my-4 alert', classes.alert)}>
	<Icon icon={classes.icon} width="20" height="20" />
	<div class="px-4 py-2">
		{@html block.html}
	</div>
</div>

<style lang="postcss">
	:global(.alert > div > :first-child) {
		@apply mt-0;
	}
	:global(.alert > div > :last-child) {
		@apply mb-0;
	}
	:global(.alert > div > p > a) {
		color: inherit;
	}
</style>
