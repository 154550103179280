import { styleToString } from '$lib/utils/style'
import { cubicOut } from 'svelte/easing'
import type { CrossfadeParams, TransitionConfig } from 'svelte/transition'

const scaleConversion = (valueA: number, scaleA: [number, number], scaleB: [number, number]) => {
    const [minA, maxA] = scaleA
    const [minB, maxB] = scaleB

    const percentage = (valueA - minA) / (maxA - minA)
    const valueB = percentage * (maxB - minB) + minB

    return valueB
}

type FlyAndScaleOptions = Partial<{
    y: number
    start: number
    duration: number
    delay: number
}>
export const flyAndScale = (node: HTMLElement, { duration = 150, y, start, delay }: FlyAndScaleOptions = { duration: 150, y: -20, start: 0.8, delay: 0 }): TransitionConfig => {
    const style = getComputedStyle(node)
    const transform = style.transform === 'none' ? '' : style.transform

    return {
        duration,
        delay,
        css: (t) => {
            const _y = scaleConversion(t, [0, 1], [y, 0])
            const scale = scaleConversion(t, [0, 1], [start, 1])

            return styleToString({
                transform: `${transform} translate3d(0, ${_y}px, 0) scale(${scale})`,
                opacity: t,
            })
        },
        easing: cubicOut,
    }
}

export type Crossfade = [
    (
        node: any,
        params: CrossfadeParams & {
            key: any
        }
    ) => () => TransitionConfig,
    (
        node: any,
        params: CrossfadeParams & {
            key: any
        }
    ) => () => TransitionConfig
]
