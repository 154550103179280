<script lang="ts">
	import { createTableOfContents } from '@melt-ui/svelte';
	import TableOfContents from './TableOfContents.svelte';
	import Menu from './menu/Menu.svelte';
	import { ID2Page, rootPageID, website } from '../content/configuration.gen';
	import ThemeController from './ThemeController.svelte';
	import Icon from '@iconify/svelte';
	import Breadcrumb from './Breadcrumb.svelte';
	import SearchBar from './search/SearchBar.svelte';
	import Footer from './Footer.svelte';
	import { theme } from '../stores/theme';
	import BtnDialog from './shared/BtnDialog.svelte';
	import { cn } from '../utils/style';
	import { browser } from '$app/environment';
	import { fly } from 'svelte/transition';
	import { onMount } from 'svelte';
	import Logo from './Logo.svelte';

	export let ID: string;
	$: page = ID2Page[ID];

	const mainContentID = 'main-content';
	const {
		elements: { item },
		states: { activeHeadingIdxs, headingsTree }
	} = createTableOfContents({
		selector: '#' + mainContentID,
		activeType: 'all',
		headingFilterFn: (heading) => !heading.hasAttribute('data-toc-ignore'),
		scrollBehaviour: 'smooth',
		scrollOffset: 84,
		exclude: ['h1', 'h5', 'h6']
	});
	let mobileToCREf: BtnDialog;
	let showFABs = false;
	const offset = 200;
	function updateHideFABsHeight() {
		const currentScroll = window.scrollY;
		const totalHeight = document.documentElement.scrollHeight;
		const visibleHeight = window.innerHeight;
		const distanceFromBottom = totalHeight - (currentScroll + visibleHeight);
		showFABs = currentScroll > 20 && distanceFromBottom > offset;
	}
	if (browser) onMount(updateHideFABsHeight);
</script>

<svelte:window on:resize|passive={updateHideFABsHeight} on:scroll|passive={updateHideFABsHeight} />

<div class="min-h-screen grid grid-cols-1 grid-rows-[min-content_auto]">
	<div class="backdrop-blur-lg bg-base-100/80 z-50">
		<header class="h-16 navbar justify-between max-w-screen-2xl mx-auto">
			<Logo {website} />

			<SearchBar class="ml-auto mr-2" />
			<button
				type="button"
				class="btn btn-circle btn-ghost btn-sm"
				on:click={theme.toggle}
				aria-label="Toggle theme"
			>
				<span class="sr-only">Toggle Theme</span>
				<ThemeController />
			</button>

			<div class="flex items-center justify-between gap-4 px-4">
				<a
					href={website.header.secondary.href}
					target="_blank"
					rel="noopener noreferrer"
					class="hidden sm:inline-block link link-hover hover:link-primary focus:link-primary"
				>
					{website.header.secondary.label}
				</a>
				<a
					href={website.header.primary.href}
					target="_blank"
					rel="noopener noreferrer"
					class="btn btn-primary btn-sm"
				>
					{website.header.primary.label}
					<Icon icon="charm:chevron-right" width="16" height="16" />
				</a>
			</div>
		</header>
	</div>
	{#if rootPageID != ID}
		<nav class="sm:hidden sticky top-0 px-4 bg-base-100/80 backdrop-blur-sm shadow-sm">
			<Breadcrumb {ID} />
		</nav>
	{/if}

	<div class="layout w-full max-w-screen-2xl mx-auto">
		<menu class="h-min sticky top-10 mt-16">
			<Menu class="hidden md:block" />
		</menu>
		<main id={mainContentID} class="w-full pt-10 prose prose-lg max-w-prose mx-auto px-4">
			<h1>{page.name}</h1>

			<BtnDialog
				bind:this={mobileToCREf}
				let:close
				class={cn(
					'top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 shadow-lg w-11/12 max-w-md overflow-y-auto'
				)}
				classButton="btn btn-block sm:btn-wide xl:hidden"
			>
				<svelte:fragment slot="button">
					<Icon icon="ic:baseline-toc" width="24" height="24" />
					<span> Open Table of Contents </span>
				</svelte:fragment>

				<div class="card-title">
					<Icon icon="ic:baseline-toc" width="24" height="24" />
					<span> Table of Contents </span>
				</div>
				<TableOfContents
					tree={$headingsTree}
					activeHeadingIdxs={$activeHeadingIdxs}
					{item}
					on:click={close}
				/>
			</BtnDialog>

			<slot />

			<Footer {ID} />

			{#if showFABs}
				<div class="fixed bottom-2 right-2 flex items-center gap-1">
					<button
						type="button"
						class="btn btn-circle bg-base-100/80 backdrop-blur-sm"
						on:click={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
						in:fly={{ duration: 300, y: 100, delay: 150 }}
						out:fly={{ duration: 350, y: 100, delay: 0 }}
					>
						<Icon icon="charm:chevron-up" width="24" height="24" />
					</button>
					<button
						type="button"
						class="btn btn-circle bg-base-100/80 backdrop-blur-sm"
						on:click={mobileToCREf.open}
						in:fly={{ duration: 300, x: 100 }}
						out:fly={{ duration: 350, x: 100, delay: 150 }}
					>
						<Icon icon="ic:baseline-toc" width="24" height="24" />
					</button>
				</div>
			{/if}
		</main>
		<aside class="h-min sticky top-10 mt-16">
			<TableOfContents
				tree={$headingsTree}
				activeHeadingIdxs={$activeHeadingIdxs}
				{item}
				class="hidden xl:block"
			/>
		</aside>
	</div>
</div>

<style lang="postcss">
	.layout {
		@apply h-full grid grid-rows-[max-content_auto];
		transition: grid-template-columns 500ms ease-in-out;
		grid-template-columns: 0rem 100vw 0rem;

		@media (min-width: 640px) {
			grid-template-columns: 0rem 100vw 0rem;
		}

		@media (min-width: 768px) {
			grid-template-columns: 16rem auto 0rem;
		}

		@media (min-width: 1024px) {
			grid-template-columns: 16rem auto 0rem;
		}

		@media (min-width: 1280px) {
			grid-template-columns: 22rem auto 22rem;
		}
	}
</style>
