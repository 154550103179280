<script lang="ts">
	import { type TableOfContentsItem, type TableOfContentsElements } from '@melt-ui/svelte';
	import { cn } from '../utils/style';
	export let tree: TableOfContentsItem[] = [];
	export let activeHeadingIdxs: number[];
	export let item: TableOfContentsElements['item'];
	export let level = 1;
	let className = '';
	export { className as class };
</script>

<ul data-level={level} class={cn('menu', className)}>
	{#if tree && tree.length}
		{#each tree as heading, i (i)}
			<li>
				<a href="#{heading.id}" {...$item(heading.id)} use:item on:click>
					{@html heading.node.textContent}
				</a>
				{#if heading.children && heading.children.length}
					<svelte:self
						tree={heading.children}
						level={level + 1}
						{activeHeadingIdxs}
						{item}
						on:click
					/>
				{/if}
			</li>
		{/each}
	{/if}
</ul>

<style lang="postcss">
	a {
		&[data-active] {
			@apply text-primary;
		}
	}
</style>
