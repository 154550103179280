<script lang="ts">
	import Icon from '@iconify/svelte';
	import { ID2Slug, ID2Page, website } from '../content/configuration.gen';
	import type { Page } from '../../gen/types';
	import { theme } from '../stores/theme';
	import ThemeController from './ThemeController.svelte';

	export let ID: string;

	let prev: Page | null = null;
	let next: Page | null = null;

	$: currentPage = ID2Page[ID];
	$: findPrev(currentPage);
	$: findNext(currentPage);

	function findPrev(page: Page) {
		prev = null;

		if (!page.parentID) return;

		// Always check the parent.
		const parent = ID2Page[page.parentID];
		if (!parent) return;

		const index = parent.childrenIDs.findIndex((childID) => childID == page.ID);
		if (!~index) throw new Error('inconsistency');

		if (index == 0) {
			prev = parent;
			return;
		}

		// If it is not the first sibling, use the previous one.
		const prevSibling = ID2Page[parent.childrenIDs[index - 1]];

		// If the previous sibling has children, use the last one.
		if (prevSibling.childrenIDs.length) {
			prev = ID2Page[prevSibling.childrenIDs[prevSibling.childrenIDs.length - 1]];
			return;
		}

		// Otherwise, point to the parent.
		prev = prevSibling;
	}

	function findNext(page: Page) {
		next = null;

		// If page has children, use its first child.
		if (page.childrenIDs.length) {
			next = ID2Page[page.childrenIDs[0]];
			return;
		}

		if (!page.parentID) return;

		// Otherwise, go up one level and take next sibling.
		const parent = ID2Page[page.parentID];
		let index = parent.childrenIDs.findIndex((childID) => childID == page.ID);
		if (!~index) throw new Error('inconsistency');

		// If it is not the last child, use the next sibling.
		if (index < parent.childrenIDs.length - 1) {
			next = ID2Page[parent.childrenIDs[index + 1]];
			return;
		}

		if (!parent.parentID) return;

		// Othersie, go get the next sibling of the parent.
		const granparent = ID2Page[parent.parentID];
		index = granparent.childrenIDs.findIndex((childID) => childID == parent.ID);
		if (!~index) throw new Error('inconsistency');

		// If it is not the last child, use the next parent.
		if (index < granparent.childrenIDs.length - 1) {
			next = ID2Page[granparent.childrenIDs[index + 1]];
			return;
		}

		// Otherwise, it is the last page.
		next = null;
	}

	const icons = {
		github: 'bi:github',
		x: 'ri:twitter-x-line',
		slack: 'gg:slack',
		discord: 'mingcute:discord-fill',
		devto: 'skill-icons:devto-dark',
		linkedin: 'uil:linkedin'
	} as const;
	const socialEntries = Object.entries(website.metadata.social) as unknown as [
		name: keyof typeof icons,
		href: string
	][];
</script>

<footer class="not-prose mt-32 pb-6 md:pb-14">
	<div class="flex flex-col sm:flex-row items-center flex-wrap gap-4">
		{#if prev}
			<a
				href={ID2Slug[prev.ID]}
				class="flex-1 btn btn-block hover:btn-primary focus:btn-primary justify-start"
			>
				<Icon icon="charm:chevron-left" width="20" height="20" />
				{prev.name}
			</a>
		{/if}

		{#if next}
			<a
				href={ID2Slug[next.ID]}
				class="flex-1 btn btn-block hover:btn-primary focus:btn-primary justify-end"
			>
				{next.name}
				<Icon icon="charm:chevron-right" width="20" height="20" />
			</a>
		{/if}
	</div>

	<div class="divider" />

	<div class="flex items-center justify-between gap-2 flex-wrap">
		<div class="w-fit grid grid-flow-col gap-1.5">
			{#each socialEntries as [name, href]}
				<a {href} target="_blank" rel="noreferrer noopener" class="btn btn-sm btn-circle btn-ghost">
					<Icon icon={icons[name]} width="20" height="20" />
				</a>
			{/each}
			<button
				type="button"
				class="btn btn-circle btn-ghost btn-sm"
				on:click={theme.toggle}
				aria-label="Toggle theme"
			>
				<span class="sr-only">Toggle Theme</span>
				<ThemeController />
			</button>
			<a
				href="/rss.xml"
				target="_blank"
				class="btn btn-sm btn-circle btn-ghost"
				aria-label="Open the RSS Feed"
			>
				<Icon icon="mingcute:rss-fill" width="20" height="20" />
			</a>
		</div>
		<a
			href="https://easypagego.com"
			target="_blank"
			rel="noreferrer noopener"
			class="link link-hover text-xs hover:link-primary focus:link-primary"
		>
			Powered by EasyPage<span class="font-semibold">GO</span>
		</a>
	</div>
</footer>
