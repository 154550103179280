import Flexsearch from 'flexsearch'
import { ID2Page, ID2Search, ID2Slug } from '../../content/configuration.gen'
import type { ReadTimeResults } from 'reading-time'

const index = new Flexsearch.Index({
	tokenize: 'forward'
})

for (const [ID, { title, content }] of Object.entries(ID2Search)) {
	index.add(ID, `${title}\n${content}`)
}

export type SearchResult = {
	slug: string
	title: string
	excerpts: string[]
	description: string
	createdAt: string
	updatedAt: string
	readingTime: ReadTimeResults
}

export async function searchByQuery(query: string): Promise<SearchResult[]> {
	const match = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$g')

	const results = await index.searchAsync(match)

	return results.map((ID) => {
		const { title, content, readingTime } = ID2Search[ID]
		const {
			createdAt,
			updatedAt,
			metadata: { description }
		} = ID2Page[ID]
		return {
			slug: ID2Slug[ID],
			createdAt,
			updatedAt,
			title: replaceTextWithMarker(title, match),
			description,
			excerpts: getMatches(content, match),
			readingTime
		}
	})
}

function getMatches(text: string, query: string, limit = 1) {
	const regex = new RegExp(query, 'gi')
	const indexes = []

	let match: RegExpExecArray | null
	while ((match = regex.exec(text)) != null && indexes.length < limit) {
		indexes.push(match.index)
	}

	return indexes.map((index) => {
		const start = index - 40
		const end = index + 200
		const excerp = text.substring(start, end).trim()

		return `... ${replaceTextWithMarker(excerp, query)} ...`
	})
}

function replaceTextWithMarker(text: string, match: string) {
	const regex = new RegExp(match, 'gi')
	return text.replaceAll(regex, (match) => `<mark class="search-result">${match}</mark>`)
}
