<svelte:options immutable />

<script lang="ts">
	import type { z } from 'zod';
	import type { TextSchema } from "../../../gen/validation/text";

	export let block: z.infer<typeof TextSchema>;
</script>

{@html block.html}
