<svelte:options immutable />

<script lang="ts">
	import type { z } from 'zod';
	import type { ImageSchema } from '../../../gen/validation/image';

	export let block: z.infer<typeof ImageSchema>;
</script>

<figure class="w-fit mx-auto">
	<img
		src={block.src}
		alt={block.alt}
		width={block.width}
		height={block.height}
		decoding="async"
		loading="lazy"
		class="bg-cover rounded-box"
	/>
	{#if block.caption}
		<figcaption class="text-center">{block.caption}</figcaption>
	{/if}
</figure>
