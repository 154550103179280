<svelte:options immutable />

<script lang="ts">
	import type { z } from 'zod';
	import { codeToHtml } from 'shiki';
	import { onMount } from 'svelte';
	import type { CodeblockSchema } from '../../../gen/validation/codeblock';
	import { theme } from '../../stores/theme';
	import { website } from '../../content/configuration.gen';
	import { browser } from '$app/environment';

	export let block: z.infer<typeof CodeblockSchema>;

	let ref: HTMLDivElement;

	onMount(async () => render());
	$: if (browser && ref) render(), $theme;

	async function render(): Promise<void> {
		ref.innerHTML = await codeToHtml(block.code, {
			lang: block.lang,
			theme: website.themes[$theme].shiki,
			transformers: [
				{
					pre(hast) {
						this.addClassToHast(
							hast,
							`m-0 p-4 h-full text-sm overflow-y-hidden font-mono tracking-normal leading-6 rounded-box`
						);
					}
				}
			]
		});
	}
</script>

<div>
	<div class="flex items-center justify-between gap-2 flex-wrap text-sm px-3 pb-0.5">
		<span>{block.name}</span>
		<span>{block.lang}</span>
	</div>
	<div bind:this={ref} class="relative mx-auto" style="height: {block.height}px;">
		<div class="sr-only">{block.code}</div>
		<span
			aria-hidden="true"
			class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-sm font-medium opacity-70 flex items-center gap-2"
		>
			<span class="loading loading-spinner"></span>
			Rendering codeblock...
		</span>
	</div>
</div>
