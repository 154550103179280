<script lang="ts">
	import { ID2Page, ID2Breadcrumb, website } from '../content/configuration.gen';
	import Icon from '@iconify/svelte';
	import Menu from './menu/Menu.svelte';
	import BtnDialog from './shared/BtnDialog.svelte';
	import Logo from './Logo.svelte';

	export let ID: string;

	$: currentPage = ID2Page[ID];
	$: anchestorsIDs = Object.entries(ID2Breadcrumb).find(([ID]) => ID == currentPage.ID)![1];
</script>

<BtnDialog
	let:close
	class="fixed inset-0 w-screen h-screen bg-base-100 z-50"
	classButton="breadcrumbs overflow-x-auto overflow-y-hidden text-xs font-medium"
>
	<ul slot="button">
		<li class="btn btn-circle btn-ghost btn-sm">
			<Icon icon="fluent:home-48-filled" width="20" height="20" />
		</li>
		{#each anchestorsIDs.slice(1).reverse() as anchestorID}
			{@const page = ID2Page[anchestorID]}
			<li>
				<span>{page.name}</span>
			</li>
		{/each}
	</ul>

	<header class="navbar h-16">
		<div class="flex-1">
			<Logo {website} />
		</div>

		<button on:click={close} class="btn btn-circle btn-ghost">
			<Icon icon="mdi:close" width="20" height="20" />
		</button>
	</header>
	<div class="h-8 flex items-center gap-4 px-4">
		<a
			href={website.header.secondary.href}
			target="_blank"
			rel="noopener noreferrer"
			class="flex-1 link link-hover hover:link-primary focus:link-primary text-center"
		>
			{website.header.secondary.label}
		</a>
		<a
			href={website.header.primary.href}
			target="_blank"
			rel="noopener noreferrer"
			class="flex-1 btn btn-primary btn-sm"
		>
			{website.header.primary.label}
			<Icon icon="charm:chevron-right" width="16" height="16" />
		</a>
	</div>
	<div class="overflow-y-auto h-[calc(100vh-4rem-2rem)]">
		<Menu />
	</div>
</BtnDialog>
