<script lang="ts">
	import type { Website } from '../../gen/types';
	import { cn } from '../utils/style';
	import { images } from '../images';

	export let website: Website;
	let className = '';
	export { className as class };
</script>

<div class={cn('btn btn-ghost text-lg font-bold tracking-wide flex items-center gap-2', className)}>
	{#if website.metadata.favicon}
		<enhanced:img src={images.favicon32} alt={website.name} class="hidden sm:block" />
	{/if}
	<span>
		{website.metadata.logo}
	</span>
</div>
