<svelte:options immutable />

<script lang="ts">
	import type { z } from 'zod';
	import Text from './Text.svelte';
	import Callout from './Callout.svelte';
	import Accordion from './Accordion.svelte';
	import Card from './Card.svelte';
	import Image from './Image.svelte';
	import Video from './Video.svelte';
	import Mermaid from './Mermaid.svelte';
	import Codeblock from './Codeblock.svelte';
	import type { ElementSchema } from "../../../gen/validation/element";

	const components: Record<(typeof block)['type'], any> = {
		text: Text,
		accordion: Accordion,
		callout: Callout,
		card: Card,
		codeblock: Codeblock,
		image: Image,
		mermaid: Mermaid,
		video: Video
	} as const;

	export let block: z.infer<typeof ElementSchema>;
</script>

<svelte:component this={components[block.type]} {block} />
