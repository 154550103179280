export function debounce<T extends (...args: any[]) => any | Promise<any>>(delay: number, func: T) {
	let timeout: NodeJS.Timer
	return (...args: any[]) => {
		clearTimeout(timeout)
		// @ts-ignore

		timeout = setTimeout(() => {
			// @ts-ignore
			func.apply(this, args)
		}, delay)
	}
}

export type Failable<T, E = Error> = { err: null, data: T } | { err: E }
export function to<T, E = Error>(promise: Promise<T>): Promise<Failable<T, E>> {
	return promise
		.then<{ data: T, err: null }>((data: T) => ({ data, err: null }))
		.catch<{ err: E }>((err) => ({ err }))
}