<svelte:options immutable />

<script lang="ts">
	import type { z } from 'zod';
	import type { VideoSchema } from '../../../gen/validation/video';

	export let block: z.infer<typeof VideoSchema>;

	const type = {
		mp4: 'video/mp4',
		webm: 'video/webm',
		ogg: 'video/ogg'
	}[block.src.split('.').pop() || 'mp4'];
</script>

<video
	src={block.src}
	width={block.width}
	height={block.height}
	autoplay={block.autoplay}
	loop={block.loop}
	muted={block.muted}
	controls={block.controls}
	playsinline
	class="mx-auto rounded-md shadow-sm"
	style="width: min({block.width}px, 100%);"
>
	<source src={block.src} {type} />
	{block.alt || 'Your browser does not support the video tag.'}
</video>
