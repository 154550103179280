<svelte:options immutable />

<script lang="ts" context="module">
	let i = 0;
	function nextID() {
		const ID = `mermaid-${i}`;
		i++;
		return ID;
	}
</script>

<script lang="ts">
	import type { z } from 'zod';
	import mermaid, { type MermaidConfig } from 'mermaid';
	import { browser } from '$app/environment';
	import type { MermaidSchema } from '../../../gen/validation/mermaid';
	import { to } from '../../utils/promise';
	import { isDark } from '../../stores/theme';

	export let block: z.infer<typeof MermaidSchema>;

	let ref: HTMLDivElement;

	const id = nextID();

	async function renderDiagram(config: MermaidConfig): Promise<void> {
		mermaid.initialize(config);
		let { code } = block;
		if (code.startsWith('```mermaid')) {
			code = code.substring('```mermaid'.length);
		}
		if (code.endsWith('```')) {
			code = code.substring(0, code.length - 3);
		}
		const rendered = await to(mermaid.render(id, code));
		if (rendered.err) {
			console.error(rendered.err);
			throw new Error('mermaid render error');
		}

		const { svg, bindFunctions } = rendered.data;
		ref.innerHTML = svg.replace(/\sstyle="[^"]*"/, '').replace(/\swidth="100%"/, '');
		bindFunctions?.(ref);
	}

	$: browser && renderDiagram({ theme: $isDark ? 'dark' : 'default' });
</script>

<div
	bind:this={ref}
	style="width: min({block.width}px, 100%); max-height: {block.height}px;"
	class="relative mx-auto"
>
	<div class="sr-only">{block.code}</div>
	<span
		aria-hidden="true"
		class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-sm font-medium opacity-70 flex items-center gap-2"
	>
		<span class="loading loading-spinner"></span>
		Rendering graph...
	</span>
</div>
