<script lang="ts">
	import type { SearchResult } from './search';
	import TimeAgo from '../TimeAgo.svelte';

	export let result: SearchResult;
</script>

<a
	href={result.slug}
	on:click
	class="w-full block focus:ring-2 rounded-box focus:ring-primary focus:outline-none"
>
	<div class="card card-compact card-bordered bg-base-100 shadow-md">
		<div class="card-body prose max-w-none">
			<h2 class="card-title gap-0">{@html result.title}</h2>
			<code class="text-xs w-fit">{result.slug}</code>
			{#each result.excerpts as excerpt}
				<p>{@html excerpt}</p>
			{:else}
				<p>{result.description}</p>
			{/each}
			<div class="flex items-center justify-between gap-2 flex-wrap text-xs">
				<em>{result.readingTime.text}</em>
				<div class="flex gap-1 items-center">
					<span> updated </span>
					<TimeAgo value={new Date(result.updatedAt)} />
				</div>
			</div>
		</div>
	</div>
</a>

<style lang="postcss">
	:global(mark) {
		@apply bg-primary;
	}
</style>
