<script lang="ts">
	import { getTimeAgoText } from '../utils/time';

	export let value: Date;
	let className = '';
	export { className as class };
</script>

<time datetime={value.toISOString()} class={className}>
	{getTimeAgoText(value)}
</time>
